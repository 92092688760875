import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { CommissionsTableComponent } from 'src/app/core/component/tables/commissions-table/commissions-table.component';
import { CommissionsTableSearchComponent } from 'src/app/core/component/tables/commissions-table-search/commissions-table-search.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-commission-page',
  templateUrl: './commission-page.component.html',
  styleUrls: ['./commission-page.component.scss']
})
export class CommissionPageComponent implements OnInit {
  @ViewChild("table", { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  searchText: string = '';

  constructor(private resolver: ComponentFactoryResolver, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    if(this.route.snapshot.data['type']){
      this.createComponentOne();
    } else {
      this.createComponentTwo();
    }
  }

  searchCommission($event: any): void {
    this.searchText = $event;
    this.createComponentTwo();
    this.router.navigate(['/commissions/search/' + this.searchText]);
  }

  createComponentOne() {
    let componentFactory = this.resolver.resolveComponentFactory(CommissionsTableComponent);

    this.container.clear();
    
    const ref = this.container.createComponent(componentFactory);
    ref.changeDetectorRef.detectChanges();
  }

  createComponentTwo() {
    let componentFactory = this.resolver.resolveComponentFactory(CommissionsTableSearchComponent);

    this.container.clear();
    
    const ref = this.container.createComponent(componentFactory);
    ref.changeDetectorRef.detectChanges();
  }
}
