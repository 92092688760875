import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from 'src/app/config/apiRoutes';
import { ChangerService } from 'src/app/core/service/changer.service';
import { LangService } from 'src/app/core/service/lang.service';
import { TokenService } from 'src/app/core/service/token.service';
import { LoggerService } from 'src/app/core/service/logger.service';
import { RefHttpService } from 'src/app/core/service/http/ref-http.service';

@Component({
  selector: 'app-commissions-table',
  templateUrl: './commissions-table.component.html',
  styleUrls: ['./commissions-table.component.scss']
})
export class CommissionsTableComponent implements OnInit, OnDestroy, AfterViewInit{
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  commissions: any;
  loading = false;
  activeCommission = null;
  activeCommissionIndex = null;
  userStatusTypes = [null];


  constructor(private http: HttpClient, private changerService: ChangerService,
              private langService: LangService, private tokenService: TokenService,
              private refHttpService: RefHttpService, private loggerService: LoggerService) { }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    this.getUserStatusTypes();
    this.retrieveData();
  }

  private getUserStatusTypes(): void {
    this.refHttpService.getAllUserStatusType()
      .subscribe((res: any) => {
        this.userStatusTypes = res;
      }, (err: any) => {
        this.loggerService.log(err);
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
      this.activeCommission = null;
      this.activeCommissionIndex = null;
    });
  }

  handleCommission(commission: any, index: number){
    this.activeCommission = commission;
    this.activeCommissionIndex = index;
  }

  private retrieveData(): void {
    this.dtOptions = {
      pageLength: 10,
      lengthChange: false,
      autoWidth: false,
      order: [[0, "desc"]],
      serverSide: true,
      searching: false,
      ordering: false,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http.get(apiRoutes.commissions.findAll + '?' + this.changerService.changeDatatablesParams(dataTablesParameters), this.tokenService.getHeadersAuth())
          .subscribe((res: any) => {
            this.commissions = res.commissionMembers;
            callback({
              recordsTotal: res.totalCount,
              recordsFiltered: res.totalCount,
              data: []
            })
          })
          
        this.loading = false;
      },
      columns: [
        {data: null},
        {data: null},
        {data: null},
        {data: null},
        {data: null},
        {data: null}
      ],
      drawCallback: () => {
        $('.paginate_button')
          .on('click', () => {
            this.activeCommission = null;
            this.activeCommissionIndex = null;
          });
      },
      language: this.langService.getDatatablesLang()
    }

  }
}
