import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token.service';
import { apiRoutes } from 'src/app/config/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class RefHttpService {

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getAllUserStatusType(): any {
    return this.http.get(apiRoutes.userStatusType, this.tokenService.getHeadersAuth());
  }

  getAllTestType(): any {
    return this.http.get(apiRoutes.testType, this.tokenService.getHeadersAuth());
  }

  getAllCommissionMemberType(): any {
    return this.http.get(apiRoutes.commissionMemberType, this.tokenService.getHeadersAuth());
  }

  getAllSubject(id: number): any {
    return this.http.get(apiRoutes.subjects + '?testTypeId=' + id, this.tokenService.getHeadersAuth());
  }

  getAllAppealType(): any {
    return this.http.get(apiRoutes.appealType, this.tokenService.getHeadersAuth());
  }
}
