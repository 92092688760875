import { Injectable } from '@angular/core';
import * as qs from 'qs';

@Injectable({
  providedIn: 'root'
})
export class ChangerService {

  constructor() { }
  
  changeParam(param: any): any {
    return qs.stringify(param);
  }


  changeDatatablesParams(params: any): any {
    let obj = {
      offset: params.start,
      limit: params.length
    }
    return qs.stringify(obj);
  }

  dateFormatter(today: any): any {
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var hh = today.getHours();
    var mmm = today.getMinutes();

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (hh < 10) {
      hh = '0' + hh;
    }
    if (mmm < 10) {
      mmm = '0' + mmm;
    }
    var date = dd + '.' + mm + '.' + yyyy + ' ' + hh + ':' + mmm;
    return date;
  }
}
