import { Component, OnInit } from '@angular/core';
import { LangService } from '../../service/lang.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-commission-template',
  templateUrl: './commission-template.component.html',
  styleUrls: ['./commission-template.component.scss']
})
export class CommissionTemplateComponent implements OnInit {
  langDropdown = false;

  constructor(public langService: LangService, private translate: TranslateService) { }

  ngOnInit() {
  }

}
