import { Component, OnInit, Input } from '@angular/core';
import { CommissionHttpService } from 'src/app/core/service/http/commission-http.service';
import { LoggerService } from 'src/app/core/service/logger.service';
import { RefHttpService } from 'src/app/core/service/http/ref-http.service';
import { LangService } from 'src/app/core/service/lang.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-commissions-table-search',
  templateUrl: './commissions-table-search.component.html',
  styleUrls: ['./commissions-table-search.component.scss']
})
export class CommissionsTableSearchComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  commissions: any;
  loading = false;
  activeCommission = null;
  activeCommissionIndex = null;
  userStatusTypes = [null];

  dataAll: any = {
    id: '',
    username: '',
    login: '',
    userStatusTypeId: '',
    phoneNumber: ''
  };

  private _search = '';

  // @Input('search')
  set search(search: string) {
    this._search = search;
    this.createTable();
  }

  get search(): string { return this._search; }


  constructor(private commissionHttpService: CommissionHttpService, private loggerService: LoggerService,
              private refHttpService: RefHttpService, private langService: LangService,
              private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    if(this.route.snapshot.paramMap.get('value')){
      this.search = this.route.snapshot.paramMap.get('value');
      this.createTable();
    };
    this.getUserStatusTypes();

    this.dtOptions = {
      pageLength: 10,
      lengthChange: false,
      autoWidth: false,
      order: [[0, "desc"]],
      searching: false,
      ordering: false,
      language: this.langService.getDatatablesLang()
    }
  }

  rerender(): void {
    this.router.navigate(['/commissions']);
  }
  
  createTable(): void {
    this.loading = true;

    this.commissionHttpService.findByLoginOrPhone(this.search)
      .subscribe((res: any) => {
        this.dataAll = res.data;
        this.loading = false;
      }, (err: any) => {
        this.dataAll = {
          id: '',
          username: '',
          login: '',
          userStatusTypeId: '',
          phoneNumber: ''
        };
        this.loggerService.log(err);
        this.loading = false;
      });
  }

  private getUserStatusTypes(): void {
    this.refHttpService.getAllUserStatusType()
      .subscribe((res: any) => {
        this.userStatusTypes = res;
      }, (err: any) => {
        this.loggerService.log(err);
      });
  }

  handleCommission(commission: any, index: number){
    this.activeCommission = commission;
    this.activeCommissionIndex = index;
  }
}
