// let prefix = "http://192.168.200.15:28080"; 
let prefix = "../.."; 
let suffix = "/online_test/appeal/operator/api/";

export const apiRoutes: any = {
    auth: {
        login: prefix + suffix + 'authenticate'
    },
    students: {
        findByTestDate: prefix + suffix + 'student/findByTestDate'
    },
    commissions: {
        save: prefix + suffix + 'commissionMember/add',
        update: prefix + suffix + 'commissionMember/update',
        findOne: prefix + suffix + 'commissionMember/find',
        findByLogin: prefix + suffix + 'commissionMember/findOne/',
        findAll: prefix + suffix + 'commissionMember/findAll',
        delete:  prefix + suffix + 'commissionMember/delete'
    },
    appealDistribution: prefix + suffix + 'appealDistribution/status',
    appealDistributionDistribute: prefix + suffix + 'appealDistribution/distribute',
    userStatusType: prefix + suffix + 'ref/userStatusType',
    commissionMemberType: prefix + suffix + 'ref/commissionMemberType',
    testType: prefix + suffix + 'ref/testType',
    subjects: prefix + suffix + 'ref/subjects',
    appealType: prefix + suffix + 'ref/appealType'
}