import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from 'src/app/config/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class LoginHttpService {

  constructor(private http: HttpClient) { }
  
  auth(form: any): any {
    return this.http.post(apiRoutes.auth.login, form, {});
  }
}
