import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token.service';
import { apiRoutes } from 'src/app/config/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class CommissionHttpService {

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  delete(form: any): any {
    return this.http.delete(apiRoutes.commissions.delete + '/' + form.userId, this.tokenService.getHeadersAuth());
  }
  
  findOne(id: number): any {
    return this.http.get(apiRoutes.commissions.findOne + '/' + id, this.tokenService.getHeadersAuth());
  }

  findByLoginOrPhone(value: string): any {
    return this.http.get(apiRoutes.commissions.findByLogin + value, this.tokenService.getHeadersAuth());
  }

  save(form: any): any {
    return this.http.post(apiRoutes.commissions.save, form, this.tokenService.getHeadersAuth());
  }

  update(form: any): any {
    return this.http.put(apiRoutes.commissions.update, form, this.tokenService.getHeadersAuth());
  }
}
