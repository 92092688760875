import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CommissionHttpService } from 'src/app/core/service/http/commission-http.service';
import { LoggerService } from 'src/app/core/service/logger.service';
import { Router } from '@angular/router';
import { LangService } from 'src/app/core/service/lang.service';

@Component({
  selector: 'app-commissions-control',
  templateUrl: './commissions-control.component.html',
  styleUrls: ['./commissions-control.component.scss']
})
export class CommissionsControlComponent implements OnInit{
  @Output() onSuggest: EventEmitter<any> = new EventEmitter();
  @Input() commission: any;
  loading = false;
  delete = false;
  deleteForm: FormGroup;
  permDeniedErr = false;

  constructor(private commissionHttpService: CommissionHttpService, private loggerService: LoggerService,
              private router: Router, public langService: LangService) { }

  ngOnInit() {
    this.createDeleteForm();
  }
  
  private createDeleteForm(): void {
    this.deleteForm = new FormGroup({
      userId: new FormControl(this.commission.id, [Validators.required])
    });
  }

  deleteCommission(): void {
    this.loading = true;
    this.commissionHttpService.delete(this.deleteForm.value)
      .subscribe((_res: any) => {
        this.onSuggest.emit();
        
        this.commission = null;
        this.delete = false;

        this.loading = false;
      }, (err: any) => {
        if(err.status == 403){
          this.permDeniedErr = true;
          this.delete = false;
        }
        this.loggerService.log(err);
        this.loading = false;
      });
  }

  handleEdit(): void {
    this.loading = true;
    this.router.navigate(['/commissions/edit/' + this.commission.id]);
  }
}
