import { Routes } from '@angular/router';
import { LoginPageComponent } from '../core/page/login-template/login-page/login-page.component';
import { LoginTemplateComponent } from '../core/page/login-template/login-template.component';
import { AuthGuard } from '../core/guard/auth.guard';
import { AuthenticatedGuard } from '../core/guard/authenticated.guard';
import { LogoutControlComponent } from '../core/component/controls/logout-control/logout-control.component';
import { CommissionTemplateComponent } from '../core/page/commission-template/commission-template.component';
import { CommissionPageComponent } from '../core/page/commission-template/commission-page/commission-page.component';
import { CommissionAddPageComponent } from '../core/page/commission-template/commission-page/commission-add-page/commission-add-page.component';
import { CommissionEditPageComponent } from '../core/page/commission-template/commission-page/commission-edit-page/commission-edit-page.component';

let loginRoutes: Routes = [
    {
        path: '',
        component: LoginPageComponent
    }
]


let commissionsRoutes: Routes = [
    {
        path: '',
        component: CommissionPageComponent,
        data: {type: true}
    },
    {
        path: 'search/:value',
        component: CommissionPageComponent,
        data: {type: false}
    },
    {
        path: 'add',
        component: CommissionAddPageComponent
    },
    {
        path: 'edit/:id',
        component: CommissionEditPageComponent
    },
    {
        path: 'logout',
        component: LogoutControlComponent
    }
]

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginTemplateComponent,
        children: loginRoutes,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'commissions',
        component: CommissionTemplateComponent,
        children: commissionsRoutes,
        canActivate: [AuthenticatedGuard],
        canActivateChild: [AuthenticatedGuard]
    }
]