import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token.service';
import { apiRoutes } from 'src/app/config/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class DistributeHttpService {

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getStatus(): any {
    return this.http.get(apiRoutes.appealDistribution, this.tokenService.getHeadersAuth());
  }

  distributeStatus(): any {
    return this.http.get(apiRoutes.appealDistributionDistribute, this.tokenService.getHeadersAuth());
  }
}
