import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginHttpService } from 'src/app/core/service/http/login-http.service';
import { TokenService } from 'src/app/core/service/token.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  form: FormGroup;
  showErrors = false;
  errorMessage: string = null;
  loading = false;

  constructor(private loginHttpService: LoginHttpService, private tokenService: TokenService,
              private router: Router, public translate: TranslateService) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm(): void {
    this.form = new FormGroup({
      login: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }
  get login(){ return this.form.get('login'); }
  get password(){ return this.form.get('password'); }
  
  submit(): void {
    if(this.form.valid){
      this.loading = true;
      this.loginHttpService.auth(this.form.value).subscribe(
        (res: any) => {
          if(res.jwt){
            this.tokenService.authorizeToken(res.jwt);
            this.router.navigate(['/commissions']);
          }
          this.loading = false;
        }, (error: any) => {
          switch (error.error.status) {
            case 0:
              this.showErrors = true;
              this.errorMessage = 'Пользователь не найден';
              break;
            default:
              break;
          }

          this.loading = false;
        }
      )
    } else {
      this.showErrors = true;
    }
  }

}
