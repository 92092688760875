import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DistributeHttpService } from 'src/app/core/service/http/distribute-http.service';
import { LoggerService } from 'src/app/core/service/logger.service';
import { LangService } from 'src/app/core/service/lang.service';

@Component({
  selector: 'app-distribute-control',
  templateUrl: './distribute-control.component.html',
  styleUrls: ['./distribute-control.component.scss']
})
export class DistributeControlComponent implements OnInit {
  @Output() searchEvent = new EventEmitter<string>();
  loading = false;
  buttonText: string;
  buttonStatus: number;
  searchString: string = '';

  constructor(private distributeHttpService: DistributeHttpService, private loggerService: LoggerService,
              private langService: LangService) { }

  ngOnInit() {
    this.getStatus();
  }

  getStatus(): void {
    this.loading = true;

    this.distributeHttpService.getStatus()
      .subscribe((res: any) => {
        this.buttonStatus = res;
        switch (res) {
          case 2:
            this.buttonText = 'Распределение выключено';
            if(this.langService.getCurrentLang() == 'kk'){
              this.buttonText = 'Бөлу өшірілген';
            }
            break;
        
          default:
            this.buttonText = 'Распределение включено';
            if(this.langService.getCurrentLang() == 'kk'){
              this.buttonText = 'Тарату қосылған';
            }
            break;
        }
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        this.loggerService.log(err);
      });
  }

  handleStatus(): void {
    this.loading = true;

    this.distributeHttpService.distributeStatus()
      .subscribe((res: any) => {
        this.buttonStatus = res;
        switch (res) {
          case 2:
            this.buttonText = 'Распределение выключено';
            if(this.langService.getCurrentLang() == 'kk'){
              this.buttonText = 'Бөлу өшірілген';
            }
            break;
        
          default:
            this.buttonText = 'Распределение включено';
            if(this.langService.getCurrentLang() == 'kk'){
              this.buttonText = 'Тарату қосылған';
            }
            break;
        }
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        this.loggerService.log(err);
      })
  }

  search(): void {
    this.searchEvent.next(this.searchString);
  }

}
