import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private tokenService: TokenService, private router: Router) { }

  log(err: any): void {
    console.error(err);
    this.checkToken(err);
  }

  checkToken(err: any): void {
    if(err.error.status == 403) {
      this.tokenService.deleteToken();
      this.router.navigate(['/']);
    }
  }
}
