import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { DataTablesModule } from 'angular-datatables';
import { NgDatepickerModule } from 'ng2-datepicker';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { routes } from './config/routes';
import { LoginFormComponent } from './core/component/forms/login-form/login-form.component';
import { LoginPageComponent } from './core/page/login-template/login-page/login-page.component';
import { LoginTemplateComponent } from './core/page/login-template/login-template.component';
import { LogoutControlComponent } from './core/component/controls/logout-control/logout-control.component';
import { CommissionTemplateComponent } from './core/page/commission-template/commission-template.component';
import { CommissionPageComponent } from './core/page/commission-template/commission-page/commission-page.component';
import { CommissionsTableComponent } from './core/component/tables/commissions-table/commissions-table.component';
import { CommissionsControlComponent } from './core/component/controls/commissions-control/commissions-control.component';
import { CommissionAddPageComponent } from './core/page/commission-template/commission-page/commission-add-page/commission-add-page.component';
import { CommissionAddFormComponent } from './core/component/forms/commission-add-form/commission-add-form.component';
import { DistributeControlComponent } from './core/component/controls/distribute-control/distribute-control.component';
import { CommissionEditFormComponent } from './core/component/forms/commission-edit-form/commission-edit-form.component';
import { CommissionEditPageComponent } from './core/page/commission-template/commission-page/commission-edit-page/commission-edit-page.component';
import { CommissionsTableSearchComponent } from './core/component/tables/commissions-table-search/commissions-table-search.component';
import { OnlyNumber } from './core/component/onlynumber.directive';

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    LoginPageComponent,
    LoginTemplateComponent,
    LogoutControlComponent,
    CommissionTemplateComponent,
    CommissionPageComponent,
    CommissionsTableComponent,
    CommissionsControlComponent,
    CommissionAddPageComponent,
    CommissionAddFormComponent,
    DistributeControlComponent,
    CommissionEditFormComponent,
    CommissionEditPageComponent,
    CommissionsTableSearchComponent,
    OnlyNumber
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    NgDatepickerModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxLoadingModule.forRoot({}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(
      routes
    ),
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [CommissionsTableComponent, CommissionsTableSearchComponent]
})
export class AppModule { }
