import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  authorizeToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getHeadersAuth(): any {
    const requestOption = { 
      headers: 
      {
        'Authorization':  'Bearer ' + localStorage.getItem('token')
      }
    };
    return requestOption;
  }

  deleteToken(): boolean {
    localStorage.removeItem('token');
    return true;
  }

  getHeadersAuthWithParams(data: any): any {
    const requestOption = { 
      params: data,
      headers: 
      {
        'Authorization':  'Bearer ' + localStorage.getItem('token')
      },
    };
    return requestOption;
  }
}
