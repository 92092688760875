import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/core/service/lang.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  langDropdown = false;

  constructor(public langService: LangService) { }

  ngOnInit() {
  }

}
