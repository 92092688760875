import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LoggerService } from 'src/app/core/service/logger.service';
import { RefHttpService } from 'src/app/core/service/http/ref-http.service';
import { CommissionHttpService } from 'src/app/core/service/http/commission-http.service';
import { Router } from '@angular/router';
import { LangService } from 'src/app/core/service/lang.service';

@Component({
  selector: 'app-commission-add-form',
  templateUrl: './commission-add-form.component.html',
  styleUrls: ['./commission-add-form.component.scss']
})
export class CommissionAddFormComponent implements OnInit {
  loading = false;
  form: FormGroup;
  showErrors = false;
  userStatusTypes: any;
  commissionMemberTypes: any;
  testTypeList: any;
  subjectList: any = [];
  appealTypeList: any;

  constructor(private refHttpService: RefHttpService, private loggerService: LoggerService,
              private commissionHttpService: CommissionHttpService, private readonly cdr: ChangeDetectorRef,
              private router: Router, public langService: LangService) { }
  
  ngOnInit() {
    this.getUserStatusTypes();
    this.getCommissionMemberType();
    this.getTestType();
    this.getAppealType();
    this.createForm();
  }

  private createForm(): void {
    this.form = new FormGroup({
      commissionMember: new FormGroup({
        username: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phoneNumber: new FormControl('', [Validators.required]),
        userStatusTypeId: new FormControl('', [Validators.required]),
        commissionMemberTypeId: new FormControl('', [Validators.required]),
      }),
      subjects: new FormArray([])
    });
  }
  get username(){ return this.form.get('commissionMember').get('username'); }
  get email(){ return this.form.get('commissionMember').get('email'); }
  get phoneNumber(){ return this.form.get('commissionMember').get('phoneNumber'); }
  get userStatusTypeId(){ return this.form.get('commissionMember').get('userStatusTypeId'); }
  get commissionMemberTypeId(){ return this.form.get('commissionMember').get('commissionMemberTypeId'); }
  get subjects(): FormArray { return this.form.get('subjects') as FormArray; };
  
  newSubject(): FormGroup {
    return new FormGroup({
      testTypeId: new FormControl('', [Validators.required]),
      subjectId: new FormControl({value: '', disabled: true}, [Validators.required]), 
      appealTypeId: new FormControl('', [Validators.required])
    })
  }

  private getUserStatusTypes(): void {
    this.loading = true;
    this.refHttpService.getAllUserStatusType()
      .subscribe((res: any) => {
        this.userStatusTypes = res;
        this.userStatusTypeId.setValue(res[0].id);
        this.loading = false;
      }, (err: any) => {
        this.loggerService.log(err);
        this.loading = false;
      });
  }

  private getCommissionMemberType(): void {
    this.loading = true;
    this.refHttpService.getAllCommissionMemberType()
      .subscribe((res: any) => {
        this.commissionMemberTypes = res;
        this.commissionMemberTypeId.setValue(res[0].id);
        this.loading = false;
      }, (err: any) => {
        this.loggerService.log(err);
        this.loading = false;
      });
  }

  private getTestType(): void {
    this.loading = true;
    this.refHttpService.getAllTestType()
      .subscribe((res: any) => {
        this.testTypeList = res;
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        this.loggerService.log(err);
      });
  }

  testTypeChange(testTypeValue: number, typeTestIndex: number): void {
    this.getSubjects(testTypeValue, typeTestIndex);
  }

  private getSubjects(id: number, index: number): void {
    this.loading = true;
    this.refHttpService.getAllSubject(id)
      .subscribe((res: any) => {
        this.loading = false;
        this.subjectList[index] = res;
        this.subjects.controls[index].get('subjectId').setValue('');
        this.subjects.controls[index].get('subjectId')['enable']();
      }, (err: any) => {
        this.loading = false;
        this.loggerService.log(err);
      });
  }

  private getAppealType(): void {
    this.loading = true;
    this.refHttpService.getAllAppealType()
      .subscribe((res: any) => {
        this.loading = false;
        this.appealTypeList = res;
      }, (err: any) => {
        this.loading = false;
        this.loggerService.log(err);
      });
  }

  addSubject(): void {
    this.subjects.push(
      this.newSubject()
    )

    this.subjectList.push([]);

    this.cdr.detectChanges();
  }

  removeSubject(id: number): void {
    this.subjects.removeAt(id);
    this.subjectList.removeAt(id);

    this.cdr.detectChanges();
  }

  submit(): void {
    this.loading = true;
    if(this.form.valid){
      if(this.subjects.controls.length == 0) {
        this.showErrors = true;
        this.loading = false;
      } else {
        this.commissionHttpService.save(this.form.value)
          .subscribe((res: any) => {
            this.router.navigate(['/commissions']);
            this.loading = false;
          }, (err: any) => {
            this.loading = false;
            this.loggerService.log(err);
          })
      }
    } else {
      this.showErrors = true;
      this.loading = false;
    }
  }
}
